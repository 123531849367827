<template>
    <footer><p>Our company &copy; 2021</p></footer>
</template>

<style lang="scss" scoped>

footer {
    background: #333;
    height: 100px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>